// Name:            Inverse
// Description:     Inverse component style for light or dark backgrounds
//
// Component:       `uk-light`
//                  `uk-dark`
//
// ========================================================================


// Variables
// ========================================================================






/* ========================================================================
   Component: Inverse
 ========================================================================== */


/*
 * Implemented class depends on the general theme color
 * `uk-light` is for light colors on dark backgrounds
 * `uk-dark` is or dark colors on light backgrounds
 */

@if ($inverse-global-color-mode == light) { .uk-light { @if (mixin-exists(hook-inverse)) {@include hook-inverse();}}}

@if ($inverse-global-color-mode == dark) { .uk-dark { @if (mixin-exists(hook-inverse)) {@include hook-inverse();}}}


// Hooks
// ========================================================================

// @mixin hook-inverse(){}
